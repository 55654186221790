<template>
  <a-modal
    v-model:visible="visible"
    title=""
    :width="modalWidth"
    :centered="true"
    :closable="false"
    :maskClosable="true"
    :bodyStyle="{ padding: 0 }"
    wrapClassName="certificate-preview-modal"
    :footer="null"
  >
    <a-dropdown :trigger="['contextmenu']" v-if="visible">
      <div
        class="preview-box"
        :style="{ width: modalWidth + 'px', height: modalHeight + 'px' }"
      >
        <div class="preview">
          <canvas :id="'canvas_' + canvasIndex"></canvas>
        </div>
      </div>
      <template #overlay>
        <a-menu>
          <a-menu-item @click="down">下 载</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-modal>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import { fabric } from "fabric";
export default {
  name: "CertificatePreview",
  setup() {
    const store = useStore();
    const state = reactive({
      visible: false,
      modalWidth: 0,
      modalHeight: 0,

      userInfo: computed(() => store.getters.userInfo),
      platformConfig: computed(() => store.getters.platformConfig),
      realName: "",

      dataSource: {},
      numberRules: [],
      canvasIndex: 0,

      fields: [],

      fontFamily: "微软雅黑",
      fontSize: 14,
      fontColor: "#000000",

      hasSealText: false,
      sealText: "公司名称",
      sealTop: 100,
      sealLeft: 100,
      sealWidth: 120,
      sealHeight: 120,
    });

    let oCanvas = null;
    let imgBg = null; // 背景
    let editEle = null; // 编辑对象
    let zoomRatio = 1;

    let fieldsObj = {};
    let sealGroup = null;
    let sealArr = [];

    const initCanvas = (url) => {
      // 创建 fabric.Image 的实例
      fabric.Image.fromURL(
        url,
        (oImg) => {
          imgBg = oImg;
          let iw = imgBg.width,
            ih = imgBg.height,
            w = 0,
            h = 0;
          if (ih > iw) {
            h =
              (window.innerHeight * 0.9 > 775
                ? window.innerHeight * 0.9
                : 775) * 2;
            zoomRatio = ih / h;
            w = iw / zoomRatio;
          } else {
            w =
              (window.innerWidth * 0.8 > 1200
                ? window.innerWidth * 0.8
                : 1200) * 2;
            zoomRatio = iw / w;
            h = ih / zoomRatio;
          }
          state.modalWidth = w / 2;
          state.modalHeight = h / 2;
          // 声明画布 宽高与图片等比
          oCanvas = new fabric.Canvas("canvas_" + state.canvasIndex, {
            width: w,
            height: h,
            // selections: false,
          });
          // 设置背景 铺满
          imgBg.set({
            left: 0,
            top: 0,
            scaleX: oCanvas.width / imgBg.width,
            scaleY: oCanvas.height / imgBg.height,
          });
          oCanvas.setBackgroundImage(imgBg, oCanvas.renderAll.bind(oCanvas));
          // 绑定事件
          //   onMouseDown();
          //   onMouseUp();
          // 回显
          renderTextShow();
        },
        {
          crossOrigin: "anonymous", // 解决跨域
        }
      );

      state.visible = true;
    };

    const addCircle = (isShow) => {
      if (!isShow) {
        oCanvas.remove(sealGroup);
        sealGroup = null;
        sealArr = [];
        return false;
      }
      //画轮廓
      let circle = new fabric.Circle({
        left: 200,
        top: 115,
        radius: 60,
        stroke: "red",
        strokeWidth: 2,
        fill: "transparent",
        originX: "center",
        originY: "center",
      });
      //画部门
      let department = new fabric.Text("公章公章公章", {
        left: 180,
        top: 145,
        fontSize: 12,
        fill: "red",
        angle: 34,
        originX: "center",
        originY: "center",
      });
      //算5角星，画五角星
      let str;
      let dig = (Math.PI / 5) * 4;
      for (let i = 0; i < 5; i++) {
        //画五角星的五条边
        let x = Math.sin(i * dig);
        let y = Math.cos(i * dig);
        str += x * 15 + "\n" + y * 15 + "\n";
      }
      let path = new fabric.Path(
        "M 0 15 L 8.816778784387099 -12.13525491562421 L -14.265847744427305 4.635254915624208 L 14.265847744427303 4.635254915624216 L -8.816778784387092 -12.135254915624214 z"
      );
      path.set({
        left: 200,
        top: 115,
        fill: "red",
        originX: "center",
        originY: "center",
      });

      let agency = state.sealText || "";
      let count = agency.length;
      let angle = (4 * Math.PI) / (3 * (count - 1));
      let agencyArr = agency.split("");
      let agencyAngle;
      let agencyX = 201;
      let agencyY = 115;
      let agencyparams;
      if (count > 0) {
        for (let i = 0; i < count; i++) {
          agencyAngle = i ? angle : (4 * Math.PI) / 6;
          agencyparams = {
            left: agencyX - 56 * Math.cos(i * agencyAngle),
            top: agencyY - 56 * Math.sin(i * agencyAngle),
            angle: -90 + i * angle * 58,
            fontSize: 12,
            borderScaleFactor: 1,
            fill: "#f00",
          };
          sealArr[i] = new fabric.Text(agencyArr[i], agencyparams);
        }
      }

      sealGroup = new fabric.Group([circle, path, ...sealArr], {
        fillType: "seal",
        left: state.sealLeft,
        top: state.sealTop,
        width: 120,
        heigth: 120,
        // selectable: false,
        angle: 0,
        scaleX: state.sealWidth / 120 / zoomRatio,
        scaleY: state.sealWidth / 120 / zoomRatio,
      });
      sealGroup.setControlsVisibility({ mtr: false });
      sealGroup["setControlVisible"]("ml", false);
      sealGroup["setControlVisible"]("mb", false);
      sealGroup["setControlVisible"]("mr", false);
      sealGroup["setControlVisible"]("mt", false);
      oCanvas.add(sealGroup);
    };

    const randerText = (
      editObj,
      text,
      fontColor,
      fontFamily,
      fontSize,
      left,
      top
    ) => {
      editEle = new fabric.IText(text, {
        fillType: "text",
        hasControls: false,
        left: left,
        top: top,
        padding: 2,
        fontFamily: fontFamily,
        fontSize: fontSize,
        fill: fontColor,
      });
      fieldsObj[editObj] = editEle;
      // 绑定选中事件
      //   editEle.on("selected", () => {
      //     state.editType = "text";
      //   });
      oCanvas.add(editEle);
    };

    const renderTextShow = () => {
      if (state.dataSource.realNameX) {
        state.fields.push(1);
        randerText(
          "field_1",
          state.realName, // 姓名
          state.dataSource.realNameColor,
          state.dataSource.realNameFont,
          Number((state.dataSource.realNameSize / zoomRatio).toFixed()),
          state.dataSource.realNameX / zoomRatio - 2,
          state.dataSource.realNameY / zoomRatio - 2
        );
      }
      if (state.dataSource.awardDateX) {
        state.fields.push(2);
        randerText(
          "field_2",
          dateFormat(
            state.dataSource.startDate || state.dataSource.startTime,
            "YYYY年MM月DD日"
          ),
          state.dataSource.awardDateColor,
          state.dataSource.awardDateFont,
          Number((state.dataSource.awardDateSize / zoomRatio).toFixed()),
          state.dataSource.awardDateX / zoomRatio - 2,
          state.dataSource.awardDateY / zoomRatio - 2
        );
      }
      if (state.dataSource.serialNumberX) {
        state.fields.push(3);
        randerText(
          "field_3",
          state.dataSource.serialNumber || "",
          state.dataSource.serialNumberColor,
          state.dataSource.serialNumberFont,
          Number((state.dataSource.serialNumberSize / zoomRatio).toFixed()),
          state.dataSource.serialNumberX / zoomRatio - 2,
          state.dataSource.serialNumberY / zoomRatio - 2
        );
      }
      if (state.dataSource.sealX) {
        state.fields.push(4);
        state.hasSealText = true;
        state.sealText = state.dataSource.sealName;
        state.sealTop = state.dataSource.sealY / zoomRatio;
        state.sealLeft = state.dataSource.sealX / zoomRatio;
        state.sealWidth = state.dataSource.sealWidth;
        state.sealHeight = state.dataSource.sealHeight;
        addCircle(true);
      }
    };

    const initData = async (data, supplement = { noOpenData: false }) => {
      state.dataSource = data;
      if (supplement.noOpenData) {
        // 不需要转义
        state.realName = supplement.realName;
      } else {
        if (state.platformConfig.platform == 26) {
          if (WWOpenData.initCanvas) {
            WWOpenData.initCanvas();
            WWOpenData.enableCanvasSharing();
          }
          const result = await new Promise((resolve, reject) => {
            WWOpenData.prefetch(
              {
                items: [
                  {
                    type: "userName",
                    id: supplement.realName || state.userInfo.realName,
                  },
                ],
              },
              (err, data) => {
                if (err) {
                  return reject(err);
                }
                resolve(data);
              }
            );
          });
          state.realName = result.items[0].data;
        } else {
          state.realName = supplement.realName || state.userInfo.realName;
        }
      }
      initCanvas(data.templatePath);
    };

    const down = () => {
      let imgUrl = oCanvas.toDataURL("image/png");
      let tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = imgUrl;
      tempLink.setAttribute(
        "download",
        state.dataSource.certificateName || state.dataSource.honorName
      );
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(imgUrl);
    };

    return {
      ...toRefs(state),
      initData,
      down,
    };
  },
};
</script>

<style lang="less">
.certificate-preview-modal {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}
</style>
<style lang="less" scoped>
.preview-box {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview {
  transform: scale(0.5);
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
</style>